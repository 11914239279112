import React from 'react';
import { Pane } from 'evergreen-ui';

import '../App.css';

/**
 * Expects required prop: 
 * - props.domain : customer, admin
 * - props.selectedPage : users, companies, products
 * - props.contents
 */
class Layout extends React.Component {

    constructor(props) {
        super(props);

        // initial state
        this.state = {
            // empty
        };
    }

    handleAuthStateChange(state) {
        console.log('amplify auth state', state);
        if (state === 'signedIn') {
            /* Do something when the user has signed-in */
            console.log('singed in with Amplify');
        }
    }

    render() {
        const containerStyle = {
            // minWidth:"352px", 
            maxWidth: "812px", 
            width: "100%",
            maxHeight: "832px",
            height: "100%", 
            backgroundColor: 'whitesmoke',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        };

        return (
            <Pane
                display="flex"
                style={containerStyle}
                flexDirection="column"
                elevation={0}
                margin="auto"
                // marginLeft="auto"
                // marginRight="auto"
                justifyContent="center"
                alignItems="center">

                    {this.props.contents}
            </Pane>
        );

    }
}

export default Layout;
