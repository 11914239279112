import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// import AWS from "@aws-sdk";
import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import GraphQLAPI from '@aws-amplify/api-graphql';
import { Spinner } from 'evergreen-ui';

import Layout from './components/layout';
import './index.css';

/** Pages */
const Home          = lazy(() => import('./routes/home'));

const YourIp        = lazy(() => import('./routes/your-ip'));
const Names         = lazy(() => import('./routes/names'));
const XeMaySpecs    = lazy(() => import('./routes/bsx2-xemay/specs'));
const XeMaySearch   = lazy(() => import('./routes/bsx2-xemay/search'));
const OtoSpecs      = lazy(() => import('./routes/bsx2-oto/specs'));
const OtoSearch     = lazy(() => import('./routes/bsx2-oto/search'));

const Writings      = lazy(() => import('./routes/writings'));
const StaticImgApp  = lazy(() => import('./routes/static-img-app'));

const About         = lazy(() => import('./routes/about'));
const Contact       = lazy(() => import('./routes/contact'));
const PrivacyPolicy = lazy(() => import('./routes/privacy-policy'));
const NotFound      = lazy(() => import('./routes/not-found'));

// Admin stuff
const Login                     = lazy(() => import('./routes/admin-me/login'));
const RealtimeAnalytics         = lazy(() => import('./routes/admin-me/realtime-analytics'));

/** AWS stuff */
// use Amplify without the stupid amplify cli overhead
// https://stackoverflow.com/questions/56455901/is-it-possible-to-use-the-amplify-framework-without-using-the-cli#:~:text=3%20Answers&text=I%20have%20learned%20that%20you,the%20amplify%20library%20as%20normal.&text=After%20that%20you%20need%20to,going%20to%20use%20with%20Amplify.

const amplifyConfig = {
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID, so confusing to find out what it is!!!
        // https://us-west-2.console.aws.amazon.com/cognito/pool/?region=us-west-2&id=us-west-2:fe0065c4-2cd6-443b-b2c6-af8442f99273
        identityPoolId: 'us-west-2:4ece5b83-7457-40bd-a74f-91063c04fd80',
        // REQUIRED - Amazon Cognito Region
        region: 'us-west-2',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'us-west-2_YAvfRF0tY',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '6dnmueujoolsincv6vqelq29i7',
    },
    API: {
        endpoints: [
            {
                name: "hosulxNetApiGW",
                endpoint: 'https://w462riqj3c.execute-api.us-west-2.amazonaws.com/prod',
                region: 'us-west-2',
                service: "execute-api",
            }
        ]
    },
};

// TODO check config on retries
// https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Config.html#maxRetries-property
// console.log('AWS', AWS);

//Initialize Amplify
Auth.configure(amplifyConfig);
API.configure(amplifyConfig);
GraphQLAPI.configure({
    aws_appsync_region: "us-west-2", // Stack region
    aws_appsync_graphqlEndpoint: "https://o4dbdbef4vew5ksxk6oz3jwv5q.appsync-api.us-west-2.amazonaws.com/graphql", // AWS AppSync endpoint URL, COPY from Aws Console!
    aws_appsync_authenticationType: 'AWS_IAM',
});

const analyticsConfig = {
    AWSPinpoint: {
        // Amazon Pinpoint App Client ID
        appId: '2907d387c10049da947f98c7671fcfac',
        // Amazon service region
        region: 'us-west-2',
        mandatorySignIn: false,
    }
}

Analytics.configure(analyticsConfig)

// Record an event
Analytics.record('hosulx.net - homepage - open');

let referrer = document.referrer;
console.log('referrer', referrer);
if (referrer) {
    Analytics.record({
        name: 'hosulx.net - referrer',
        attributes: { 'from': referrer }
    });
} else {
    Analytics.record({
        name: 'hosulx.net - referrer',
        attributes: { 'from': 'direct' }
    });
}

/** App */
const App = () => (
    <Router>
        <Suspense fallback={<Layout contents={<div><Spinner /></div>} />}>
            <Switch>
                <Route exact path="/"                   component={Home} />

                <Route path="/apps/bsx2-xemay/specs"    component={XeMaySpecs} />
                <Route path="/apps/bsx2-xemay/search"    component={XeMaySearch} />
                
                <Route path="/apps/bsx2-oto/specs"      component={OtoSpecs} />
                <Route path="/apps/bsx2-oto/search"     component={OtoSearch} />

                <Route path="/apps/your-ip"             component={YourIp} />
                <Route exact path="/apps/names/:name"   component={Names} />
                <Route path="/apps/names"               component={Names} />

                <Route path="/writings/:name"           component={Writings} />
                <Route path="/apps/:imgName/:appName"   component={StaticImgApp} />

                <Route path="/privacy_policy"           component={PrivacyPolicy} />
                <Route path="/about"                    component={About} />
                <Route path="/contact"                  component={Contact} />

                <Route path="/admin-me/realtime-analytics"  component={RealtimeAnalytics} />
                <Route path="/admin-me/login"               component={Login} />

                {/* CATCH ALL */}
                <Route                                  component={NotFound} />
                
            </Switch>
        </Suspense>
    </Router>
);

// ========================================

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
